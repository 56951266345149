<template>
    <b-card>
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="mailSettingsEditForm"
            @submit="handleSubmit"
        >
            <h6 class="section-label mb-2 text-dark">
                {{ $t("modules.systemSettings.mail.description") }}
            </h6>
            <b-row class="mb-1">
                <b-col>
                    <label class="font-weight-bold" for="enabled">{{
                        $t("modules.systemSettings.mail.fields.enable.label")
                    }}</label>
                    <b-form-checkbox
                        id="enabled"
                        v-model="formData.enabled"
                        class="custom-control-primary mb-1 ml-5"
                        name="enabled"
                        switch
                        size="lg"
                        :help="$t('modules.systemSettings.mail.help')"
                    >
                    </b-form-checkbox>
                    <span>{{ $t("modules.systemSettings.mail.help") }}</span>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <h6 class="section-label mb-2 text-dark">
                        {{
                            $t(
                                "modules.systemSettings.mail.additionalInformation"
                            )
                        }}
                    </h6>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <formulateInput
                        class="w-50"
                        :label="
                            $t(
                                'modules.systemSettings.mail.fields.senderName.label'
                            )
                        "
                        :help="
                            $t(
                                'modules.systemSettings.mail.fields.senderName.help'
                            )
                        "
                        name="senderName"
                        type="text"
                    />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <formulateInput
                        class="w-50"
                        :label="
                            $t(
                                'modules.systemSettings.mail.fields.replyToAddress.label'
                            )
                        "
                        :help="
                            $t(
                                'modules.systemSettings.mail.fields.replyToAddress.help'
                            )
                        "
                        name="replyToAddress"
                        validation="optional|max:255,length|email"
                        type="text"
                    />
                </b-col>
            </b-row>

            <h6 class="section-label mb-2">
                {{ $t("modules.systemSettings.mail.sendTest") }}
            </h6>
            <b-row class="mb-1">
                <b-col>
                    <formulateInput
                        class="w-50"
                        :label="
                            $t(
                                'modules.systemSettings.mail.fields.testingAddress.label'
                            )
                        "
                        :help="
                            $t(
                                'modules.systemSettings.mail.fields.testingAddress.help'
                            )
                        "
                        name="emailAddress"
                        type="text"
                    />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <b-button variant="primary" @click="testEmailSending">
                        <feather-icon icon="SendIcon" size="15" />
                        {{
                            $t(
                                "modules.systemSettings.mail.fields.testingAddress.testSending"
                            )
                        }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-center">
                    <formulateErrors />
                </b-col>
            </b-row>

            <b-row class="justify-content-center align-self-center mt-2 ml-0">
                <formulateInput
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
            </b-row>
        </formulateForm>
    </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton, BFormCheckbox } from "bootstrap-vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SettingsService from "./SettingsService"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormCheckbox,
    },
    data() {
        return {
            formData: {},
        }
    },
    mounted() {
        this.getSettingsValues()
    },
    methods: {
        async testEmailSending() {
            try {
                await SettingsService.post("/mail/test", {
                    emailAddress: this.formData.emailAddress,
                })
                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation("mailSettingsEditForm")
            } catch (e) {
                this.$formulate.handle(e, "mailSettingsEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        getSettingsValues() {
            SettingsService.get("/mail").then(response => {
                this.formData = response.data.data
            })
        },
        async handleSubmit() {
            try {
                const response = await SettingsService.patch(
                    "/mail",
                    this.formData
                )
                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation("mailSettingsEditForm")
                this.formData = response.data.data
            } catch (e) {
                this.$formulate.handle(e, "mailSettingsEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
