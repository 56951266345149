var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('formulateForm',{attrs:{"name":"materialDonationSettingsEditForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('h6',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.materialDonation.formSections.generalInformation" ))+" ")]),_c('b-row',{staticClass:"mr-0 ml-0"},[_c('formulateInput',{staticClass:"mb-2",attrs:{"label":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.thankYouMessage.label'
                            ),"name":"thankYouMessage","placeholder":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.thankYouMessage.label'
                            ),"type":"textarea","rows":"7","help":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.thankYouMessage.help'
                            )}})],1),_c('b-row',{staticClass:"mr-0 ml-0"},[_c('formulateInput',{staticClass:"w-100",attrs:{"label":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.importantObservations.label'
                            ),"name":"importantObservations","placeholder":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.importantObservations.label'
                            ),"type":"textarea","rows":"7","help":_vm.$t(
                                'modules.systemSettings.materialDonation.fields.importantObservations.help'
                            )}})],1)],1),_c('b-col',[_c('h6',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.materialDonation.formSections.emailMessage" ))+" ")]),_c('b-card',{staticClass:"mb-2",attrs:{"borderVariant":"primary"}},[_c('b-card-title',{staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.materialDonation.formSections.variables" ))+" ")]),_vm._l((_vm.materialDonationVariables),function(item,index){return _c('b-card-text',{key:index,staticClass:"d-block text-primary font-weight-bold mt-1"},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.materialDonation.fields.thankYouEmailSubject.label'
                        ),"placeholder":_vm.$t(
                            'modules.systemSettings.materialDonation.fields.thankYouEmailSubject.placeholder'
                        ),"name":"thankYouEmailSubject","type":"text"}}),_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.materialDonation.fields.thankYouEmailMessage.label'
                        ),"placeholder":_vm.$t(
                            'modules.systemSettings.materialDonation.fields.thankYouEmailMessage.placeholder'
                        ),"name":"thankYouEmailMessage","type":"textarea","rows":"7"}})],1)],1),_c('h6',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t("modules.systemSettings.mail.sendTest"))+" ")]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('formulateInput',{staticClass:"w-50",attrs:{"label":_vm.$t(
                            'modules.systemSettings.mail.fields.testingAddress.label'
                        ),"help":_vm.$t(
                            'modules.systemSettings.mail.fields.testingAddress.help'
                        ),"name":"emailAddress","type":"text"}})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.testEmailSending}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"15"}}),_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.mail.fields.testingAddress.testSending" ))+" ")],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$t('common.crud.updating')
                        : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }