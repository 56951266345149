<template>
    <b-tabs
        vertical
        contentClass="col-12 col-md-9 mt-1 mt-md-0"
        pills
        navWrapperClass="col-md-3 col-12"
        navClass="nav-left"
    >
        <b-tab active>
            <template #title>
                <feather-icon icon="InfoIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{
                    $t("modules.systemSettings.organizationInfo.name")
                }}</span>
            </template>
            <organizationInfoSettings />
        </b-tab>

        <b-tab>
            <template #title>
                <feather-icon icon="PackageIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{
                    $t("modules.systemSettings.materialDonation.name")
                }}</span>
            </template>
            <materialDonationSettings />
        </b-tab>

        <b-tab>
            <template #title>
                <feather-icon icon="AtSignIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">{{
                    $t("modules.systemSettings.mail.name")
                }}</span>
            </template>
            <mailSettings />
        </b-tab>
    </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue"
import OrganizationInfoSettings from "./OrganizationInfoSettings.vue"
import MaterialDonationSettings from "./MaterialDonationSettings.vue"
import MailSettings from "./MailSettings.vue"

export default {
    components: {
        BTabs,
        BTab,
        OrganizationInfoSettings,
        MaterialDonationSettings,
        MailSettings,
    },
}
</script>
