<template>
    <b-card>
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="organizationInfoSettingsEditForm"
            @submit="handleSubmit"
        >
            <h6 class="section-label mb-2">
                {{
                    $t(
                        "modules.systemSettings.organizationInfo.formSections.basicInformation"
                    )
                }}
            </h6>
            <b-row>
                <b-col class="mb-2">
                    <b-media noBody>
                        <b-media-aside>
                            <b-link>
                                <b-img
                                    rounded
                                    :src="formData.logoUrl"
                                    height="80"
                                />
                            </b-link>
                        </b-media-aside>

                        <b-media-body class="mt-75 ml-75">
                            <formulateInput
                                :label="
                                    $t(
                                        'modules.systemSettings.organizationInfo.fields.logoUrl.label'
                                    )
                                "
                                name="logoUrl"
                                :placeholder="
                                    $t(
                                        'modules.systemSettings.organizationInfo.fields.logoUrl.placeholder'
                                    )
                                "
                                type="text"
                                :help="
                                    $t(
                                        'modules.systemSettings.organizationInfo.fields.logoUrl.help'
                                    )
                                "
                            />
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.name.label'
                            )
                        "
                        name="name"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.name.label'
                            )
                        "
                        type="text"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.corporateName.label'
                            )
                        "
                        name="corporateName"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.corporateName.label'
                            )
                        "
                        type="text"
                    />
                </b-col>

                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.businessTaxId.label'
                            )
                        "
                        name="businessTaxId"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.businessTaxId.placeholder'
                            )
                        "
                        type="text"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.tradingName.label'
                            )
                        "
                        name="tradingName"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.tradingName.label'
                            )
                        "
                        type="text"
                    />
                </b-col>

                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.foundationDate.label'
                            )
                        "
                        name="foundationDate"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.foundationDate.label'
                            )
                        "
                        type="custom-date"
                        @update:date="formData.foundationDate = $event"
                    />
                </b-col>
            </b-row>

            <h6 class="section-label mt-2 mb-2">
                {{
                    $t(
                        "modules.systemSettings.organizationInfo.formSections.contactInformation"
                    )
                }}
            </h6>

            <b-row>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.primaryPhoneNumber.label'
                            )
                        "
                        name="primaryPhoneNumber"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.primaryPhoneNumber.placeholder'
                            )
                        "
                        type="text"
                    />
                </b-col>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.secondaryPhoneNumber.label'
                            )
                        "
                        name="secondaryPhoneNumber"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.secondaryPhoneNumber.placeholder'
                            )
                        "
                        type="text"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.websiteAddress.label'
                            )
                        "
                        name="websiteAddress"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.websiteAddress.placeholder'
                            )
                        "
                        type="text"
                    />
                </b-col>
                <b-col>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.administrativeEmailAddress.label'
                            )
                        "
                        name="administrativeEmailAddress"
                        :placeholder="
                            $t(
                                'modules.systemSettings.organizationInfo.fields.administrativeEmailAddress.placeholder'
                            )
                        "
                        type="text"
                    />
                </b-col>
            </b-row>

            <h6 class="section-label mt-2 mb-2">
                {{
                    $t(
                        "modules.systemSettings.organizationInfo.formSections.additionalInformation"
                    )
                }}
            </h6>

            <formulateInput
                :label="
                    $t(
                        'modules.systemSettings.organizationInfo.fields.organizationObjective.label'
                    )
                "
                name="organizationObjective"
                :placeholder="
                    $t(
                        'modules.systemSettings.organizationInfo.fields.organizationObjective.label'
                    )
                "
                type="textarea"
            />

            <formulateInput
                :label="
                    $t(
                        'modules.systemSettings.organizationInfo.fields.organizationDescription.label'
                    )
                "
                name="organizationDescription"
                :placeholder="
                    $t(
                        'modules.systemSettings.organizationInfo.fields.organizationDescription.label'
                    )
                "
                type="textarea"
            />

            <b-row>
                <b-col class="d-flex justify-content-center">
                    <formulateErrors />
                </b-col>
            </b-row>

            <b-row class="justify-content-center align-self-center mt-2 ml-0">
                <formulateInput
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
            </b-row>
        </formulateForm>
    </b-card>
</template>

<script>
import {
    BCard,
    BCol,
    BImg,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BRow,
} from "bootstrap-vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SettingsService from "./SettingsService"

export default {
    components: {
        BLink,
        BMediaBody,
        BImg,
        BMediaAside,
        BMedia,
        BRow,
        BCol,
        BCard,
    },
    data() {
        return {
            formData: {},
        }
    },
    mounted() {
        this.getSettingsValues()
    },
    methods: {
        getSettingsValues() {
            SettingsService.get("/organization-info").then(response => {
                this.formData = response.data.data
            })
        },
        async handleSubmit() {
            try {
                const response = await SettingsService.patch(
                    "/organization-info",
                    this.formData
                )
                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation(
                    "organizationInfoSettingsEditForm"
                )
                this.formData = response.data.data
            } catch (e) {
                this.$formulate.handle(e, "organizationInfoSettingsEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
