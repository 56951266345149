var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('formulateForm',{attrs:{"name":"organizationInfoSettingsEditForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('h6',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.organizationInfo.formSections.basicInformation" ))+" ")]),_c('b-row',[_c('b-col',{staticClass:"mb-2"},[_c('b-media',{attrs:{"noBody":""}},[_c('b-media-aside',[_c('b-link',[_c('b-img',{attrs:{"rounded":"","src":_vm.formData.logoUrl,"height":"80"}})],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('formulateInput',{attrs:{"label":_vm.$t(
                                    'modules.systemSettings.organizationInfo.fields.logoUrl.label'
                                ),"name":"logoUrl","placeholder":_vm.$t(
                                    'modules.systemSettings.organizationInfo.fields.logoUrl.placeholder'
                                ),"type":"text","help":_vm.$t(
                                    'modules.systemSettings.organizationInfo.fields.logoUrl.help'
                                )}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.name.label'
                        ),"name":"name","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.name.label'
                        ),"type":"text"}})],1)],1),_c('b-row',[_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.corporateName.label'
                        ),"name":"corporateName","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.corporateName.label'
                        ),"type":"text"}})],1),_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.businessTaxId.label'
                        ),"name":"businessTaxId","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.businessTaxId.placeholder'
                        ),"type":"text"}})],1)],1),_c('b-row',[_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.tradingName.label'
                        ),"name":"tradingName","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.tradingName.label'
                        ),"type":"text"}})],1),_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.foundationDate.label'
                        ),"name":"foundationDate","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.foundationDate.label'
                        ),"type":"custom-date"},on:{"update:date":function($event){_vm.formData.foundationDate = $event}}})],1)],1),_c('h6',{staticClass:"section-label mt-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.organizationInfo.formSections.contactInformation" ))+" ")]),_c('b-row',[_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.primaryPhoneNumber.label'
                        ),"name":"primaryPhoneNumber","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.primaryPhoneNumber.placeholder'
                        ),"type":"text"}})],1),_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.secondaryPhoneNumber.label'
                        ),"name":"secondaryPhoneNumber","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.secondaryPhoneNumber.placeholder'
                        ),"type":"text"}})],1)],1),_c('b-row',[_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.websiteAddress.label'
                        ),"name":"websiteAddress","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.websiteAddress.placeholder'
                        ),"type":"text"}})],1),_c('b-col',[_c('formulateInput',{attrs:{"label":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.administrativeEmailAddress.label'
                        ),"name":"administrativeEmailAddress","placeholder":_vm.$t(
                            'modules.systemSettings.organizationInfo.fields.administrativeEmailAddress.placeholder'
                        ),"type":"text"}})],1)],1),_c('h6',{staticClass:"section-label mt-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.organizationInfo.formSections.additionalInformation" ))+" ")]),_c('formulateInput',{attrs:{"label":_vm.$t(
                    'modules.systemSettings.organizationInfo.fields.organizationObjective.label'
                ),"name":"organizationObjective","placeholder":_vm.$t(
                    'modules.systemSettings.organizationInfo.fields.organizationObjective.label'
                ),"type":"textarea"}}),_c('formulateInput',{attrs:{"label":_vm.$t(
                    'modules.systemSettings.organizationInfo.fields.organizationDescription.label'
                ),"name":"organizationDescription","placeholder":_vm.$t(
                    'modules.systemSettings.organizationInfo.fields.organizationDescription.label'
                ),"type":"textarea"}}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$t('common.crud.updating')
                        : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }