var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('formulateForm',{attrs:{"name":"mailSettingsEditForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('h6',{staticClass:"section-label mb-2 text-dark"},[_vm._v(" "+_vm._s(_vm.$t("modules.systemSettings.mail.description"))+" ")]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"enabled"}},[_vm._v(_vm._s(_vm.$t("modules.systemSettings.mail.fields.enable.label")))]),_c('b-form-checkbox',{staticClass:"custom-control-primary mb-1 ml-5",attrs:{"id":"enabled","name":"enabled","switch":"","size":"lg","help":_vm.$t('modules.systemSettings.mail.help')},model:{value:(_vm.formData.enabled),callback:function ($$v) {_vm.$set(_vm.formData, "enabled", $$v)},expression:"formData.enabled"}}),_c('span',[_vm._v(_vm._s(_vm.$t("modules.systemSettings.mail.help")))])],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('h6',{staticClass:"section-label mb-2 text-dark"},[_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.mail.additionalInformation" ))+" ")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('formulateInput',{staticClass:"w-50",attrs:{"label":_vm.$t(
                            'modules.systemSettings.mail.fields.senderName.label'
                        ),"help":_vm.$t(
                            'modules.systemSettings.mail.fields.senderName.help'
                        ),"name":"senderName","type":"text"}})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('formulateInput',{staticClass:"w-50",attrs:{"label":_vm.$t(
                            'modules.systemSettings.mail.fields.replyToAddress.label'
                        ),"help":_vm.$t(
                            'modules.systemSettings.mail.fields.replyToAddress.help'
                        ),"name":"replyToAddress","validation":"optional|max:255,length|email","type":"text"}})],1)],1),_c('h6',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t("modules.systemSettings.mail.sendTest"))+" ")]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('formulateInput',{staticClass:"w-50",attrs:{"label":_vm.$t(
                            'modules.systemSettings.mail.fields.testingAddress.label'
                        ),"help":_vm.$t(
                            'modules.systemSettings.mail.fields.testingAddress.help'
                        ),"name":"emailAddress","type":"text"}})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.testEmailSending}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"15"}}),_vm._v(" "+_vm._s(_vm.$t( "modules.systemSettings.mail.fields.testingAddress.testSending" ))+" ")],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$t('common.crud.updating')
                        : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}})],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }