import Api from "@/services/api"

const axiosInstance = Api.axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/settings`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
})
export default axiosInstance
