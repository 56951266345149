<template>
    <b-card>
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="materialDonationSettingsEditForm"
            @submit="handleSubmit"
        >
            <b-row class="mb-2">
                <b-col>
                    <h6 class="section-label mb-2">
                        {{
                            $t(
                                "modules.systemSettings.materialDonation.formSections.generalInformation"
                            )
                        }}
                    </h6>
                    <b-row class="mr-0 ml-0">
                        <formulateInput
                            :label="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.thankYouMessage.label'
                                )
                            "
                            name="thankYouMessage"
                            :placeholder="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.thankYouMessage.label'
                                )
                            "
                            type="textarea"
                            class="mb-2"
                            rows="7"
                            :help="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.thankYouMessage.help'
                                )
                            "
                        />
                    </b-row>
                    <b-row class="mr-0 ml-0">
                        <formulateInput
                            :label="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.importantObservations.label'
                                )
                            "
                            name="importantObservations"
                            :placeholder="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.importantObservations.label'
                                )
                            "
                            type="textarea"
                            rows="7"
                            class="w-100"
                            :help="
                                $t(
                                    'modules.systemSettings.materialDonation.fields.importantObservations.help'
                                )
                            "
                        />
                    </b-row>
                </b-col>

                <b-col>
                    <h6 class="section-label mb-2">
                        {{
                            $t(
                                "modules.systemSettings.materialDonation.formSections.emailMessage"
                            )
                        }}
                    </h6>
                    <b-card borderVariant="primary" class="mb-2">
                        <b-card-title style="font-size: 1rem">
                            {{
                                $t(
                                    "modules.systemSettings.materialDonation.formSections.variables"
                                )
                            }}
                        </b-card-title>

                        <b-card-text
                            v-for="(item, index) in materialDonationVariables"
                            :key="index"
                            class="d-block text-primary font-weight-bold mt-1"
                        >
                            {{ item }}
                        </b-card-text>
                    </b-card>
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.materialDonation.fields.thankYouEmailSubject.label'
                            )
                        "
                        :placeholder="
                            $t(
                                'modules.systemSettings.materialDonation.fields.thankYouEmailSubject.placeholder'
                            )
                        "
                        name="thankYouEmailSubject"
                        type="text"
                    />
                    <formulateInput
                        :label="
                            $t(
                                'modules.systemSettings.materialDonation.fields.thankYouEmailMessage.label'
                            )
                        "
                        :placeholder="
                            $t(
                                'modules.systemSettings.materialDonation.fields.thankYouEmailMessage.placeholder'
                            )
                        "
                        name="thankYouEmailMessage"
                        type="textarea"
                        rows="7"
                    />
                </b-col>
            </b-row>
            <h6 class="section-label mb-2">
                {{ $t("modules.systemSettings.mail.sendTest") }}
            </h6>
            <b-row class="mb-1">
                <b-col>
                    <formulateInput
                        class="w-50"
                        :label="
                            $t(
                                'modules.systemSettings.mail.fields.testingAddress.label'
                            )
                        "
                        :help="
                            $t(
                                'modules.systemSettings.mail.fields.testingAddress.help'
                            )
                        "
                        name="emailAddress"
                        type="text"
                    />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col>
                    <b-button variant="primary" @click="testEmailSending">
                        <feather-icon icon="SendIcon" size="15" />
                        {{
                            $t(
                                "modules.systemSettings.mail.fields.testingAddress.testSending"
                            )
                        }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-center">
                    <formulateErrors />
                </b-col>
            </b-row>

            <b-row class="justify-content-center align-self-center mt-2 ml-0">
                <formulateInput
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
            </b-row>
        </formulateForm>
    </b-card>
</template>

<script>
import {
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BCol,
    BRow,
} from "bootstrap-vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SettingsService from "./SettingsService"

export default {
    components: {
        BButton,
        BCardText,
        BRow,
        BCol,
        BCard,
        BCardTitle,
    },
    data() {
        return {
            formData: {},
            materialDonationVariables: [
                "{{primeiroNomeDoador}}",
                "{{nomeCompletoDoador}}",
                "{{enderecoRetirada}}",
                "{{dataRetirada}}",
                "{{numeroDoacao}}",
                "{{acao}}",
            ],
        }
    },
    mounted() {
        this.getSettingsValues()
    },
    methods: {
        async testEmailSending() {
            try {
                await SettingsService.post("/material-donation/test", {
                    emailAddress: this.formData.emailAddress,
                })
                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation(
                    "materialDonationSettingsEditForm"
                )
            } catch (e) {
                this.$formulate.handle(e, "materialDonationSettingsEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        getSettingsValues() {
            SettingsService.get("/material-donation").then(response => {
                this.formData = response.data.data
            })
        },
        async handleSubmit() {
            try {
                const response = await SettingsService.patch(
                    "/material-donation",
                    this.formData
                )
                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation(
                    "materialDonationSettingsEditForm"
                )
                this.formData = response.data.data
            } catch (e) {
                this.$formulate.handle(e, "materialDonationSettingsEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
